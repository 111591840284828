import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { useGetallUserQuery, useSubmitKycMutation } from "../../redux/userApi";
import Sidebar from "../widgets/Sidebar";
import { imageUrl } from "../constant/BaseUrl";

const KycStatus = () => {
  const { data } = useGetallUserQuery();
  const [image, setImage] = useState("");

  function closeModal() {
    console.log("console");
    document.getElementById("supportImageModel").style.display = "none";
  }

  function setImageAndOpenModal(imageSrc) {
    console.log("imageSrc", imageSrc);
    // document.getElementById("modalImage").src = imageSrc;
    openModal();
  }

  function openModal() {
    document.getElementById("supportImageModel").style.display = "block";
  }

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3 p-md-4  bg2 br20">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="mb-0">KYC Information</h5>
                  <span className="ml-auto"> 
                    KYC is {data && data.kyc_status}
                  </span>
                </div>
                <hr />
                <div className="row ">
                  <div className="col-md-4 col-lg-2 mt-3 ">
                    <label>Email:</label>
                  </div>
                  <div className="col-md-6 col-lg-3 mt-md-3">
                    <span>{data.email ? data.email : "-"}</span>
                  </div>

                  <div className="col-md-4 col-lg-3 mt-3">
                    <label>Mobile Number:</label>
                  </div>
                  <div className="col-md-6 col-lg-3 mt-md-3 ">
                    <span>
                      +{data.mobile_number ? data.mobile_number : "-"}
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-4 col-lg-2 mt-3 ">
                    <label>Country:</label>
                  </div>
                  <div className="col-md-6 col-lg-3 mt-md-3 ">
                    <span>{data.country_code ? data.country_code : "-"}</span>
                  </div>

                  <div className="col-md-4 col-lg-3 mt-3">
                    <label>Document Number:</label>
                  </div>
                  <div className="col-md-6 col-lg-3 mt-md-3">
                    <span>{data.id_number ? data.id_number : "-"}</span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 mt-3 col-lg-5">
                    <label className="d-block"> Document Front Image:</label>
                    <span> 
                      <img
                        className="status_img"
                        src={imageUrl + "/static/kycImages/" + data.id_front}
                        alt="Doc"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImageAndOpenModal();
                          setImage(
                            imageUrl + "/static/kycImages/" + data.id_front
                          );
                        }}
                      />
                    </span>
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="d-block"> Document Back Image:</label>

                    <span>
                      {" "}
                      <img
                        className="status_img"
                        src={imageUrl + "/static/kycImages/" + data.id_back}
                        alt="Doc"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImageAndOpenModal();
                          setImage(
                            imageUrl + "/static/kycImages/" + data.id_back
                          );
                        }}
                      />
                    </span>
                  </div>

                  <div className="col-md-6 mt-3 col-lg-5">
                    <label className="d-block">Utility Bill Image:</label>

                    <span>
                      {" "}
                      <img
                        className="status_img"
                        src={
                          imageUrl + "/static/kycImages/" + data.utility_bill
                        }
                        alt="Doc"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImageAndOpenModal();
                          setImage(
                            imageUrl + "/static/kycImages/" + data.utility_bill
                          );
                        }}
                      />
                    </span>
                  </div>

                  <div className="col-md-6 mt-3">
                    <label className="d-block">Selfie:</label>

                    <span>
                      {" "}
                      <img
                        className="status_img"
                        src={imageUrl + "/static/kycImages/" + data.selfie}
                        alt="Doc"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImageAndOpenModal();
                          setImage(
                            imageUrl + "/static/kycImages/" + data.selfie
                          );
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal" id="supportImageModel">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <span className="close mr-2" onClick={closeModal}>
                  &times;
                </span>
                <div className="modal-body p-0 pt-3">
                  <img className="img-fluid" src={image} alt="noimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default KycStatus;
