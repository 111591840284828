import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {
  useGetCryptoPortfolioQuery,
  useGetallUserQuery,
} from "../../redux/userApi";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import {
  useGetSelectedLeverageQuery,
  useGetUserForexPortfolioMutation,
  useLeverageByUserQuery,
  useUpdateLeverageUserMutation,
} from "../../redux/forexApi";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const token = localStorage.getItem("jwtToken");
  const { t } = useTranslation();
  const { data } = useGetallUserQuery();
  const { data: cryptoPortfolio } = useGetCryptoPortfolioQuery({
    skip: !token,
  });
  const [userPortfolioForex] = useGetUserForexPortfolioMutation();
  const leverageUser = useLeverageByUserQuery();
  const [updateLeverage] = useUpdateLeverageUserMutation();
  const { data: selectedLeverages, refetch } = useGetSelectedLeverageQuery();
  const [leverageList, setLeverageList] = useState([]);
  const [cryptoPortfolioValue, setCryptoPortfolioValue] = useState(0.0);
  const [floatingProfits, setFloatingProfits] = useState(0.0);
  const [equityPortfolio, setEquityPortfolio] = useState(0.0);
  const [userLeverage, setUserLeverage] = useState("");
  const username = data?.user_name || "N/A";

  useEffect(() => {
    if (leverageUser.data) {
      setLeverageList(leverageUser.data);
    }
  }, [leverageUser]);

  useEffect(() => {
    if (token) {
      getForexPortfolio();
      getEquityPortfolio();
    }
  }, []);

  const getForexPortfolio = async () => {
    const data = {
      symbolType: "forex",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setFloatingProfits(resp.data);
    }
  };

  const getEquityPortfolio = async () => {
    const data = {
      symbolType: "equity",
    };
    const res = await userPortfolioForex(data);
    const resp = res.data;
    if (resp.status === true) {
      setEquityPortfolio(resp.data);
    }
  };

  useEffect(() => {
    if (cryptoPortfolio) {
      if (cryptoPortfolio.length > 0) {
        setCryptoPortfolioValue(cryptoPortfolio[0].USDT);
      }
    }
    if (selectedLeverages) {
      setUserLeverage(selectedLeverages.amount);
    } else {
      setUserLeverage(1);
    }
  }, [cryptoPortfolio, selectedLeverages]);

  const handleChange = async (e) => {
    const { value } = e.target;
    if (!value) {
      return;
    }
    const leverageData = {
      selectedId: value,
    };
    const resp = await updateLeverage(leverageData);

    if (resp.data.status) {
      toast.success(resp.data.message);
      refetch();
    } else {
      toast.error(resp.data.message);
    }
  };

  function formatToThreeDecimalPlaces(value) {
    // Convert the value to a number (if it's not already)
    const numberValue = Number(value);

    // Format the number to three decimal places
    const formattedValue = numberValue.toFixed(3);

    return formattedValue;
  }

  return (
    <div>
      <Navbar />
      <section className="p70  pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="p-3    bg2 br20 mb-4">
                <h5 className="mb-2">{t("For You")}</h5>
                <div className=" row ">
                  <div className="col-md-8">
                    <div className=" row  dashbord">
                      <div className="col-4">
                        <Link to="/crypto/BTC/USDT" className="checkbox">
                          <label className="checkbox-wrapper">
                            <span className="checkbox-tile">
                              <span className="checkbox-icon">
                                <img src="/img/crypto.png" alt="" />
                              </span>
                              <span className="checkbox-label mt-2">
                                {t("Crypto")}
                              </span>
                            </span>
                          </label>
                        </Link>
                      </div>
                      <div className="col-4">
                        <Link to="/forex-equity/GOOGL/" className="checkbox">
                          <label className="checkbox-wrapper">
                            <span className="checkbox-tile">
                              <span className="checkbox-icon">
                                <img src="/img/equity.png" alt="" />
                              </span>
                              <span className="checkbox-label mt-2">
                                {t("Equity")}
                              </span>
                            </span>
                          </label>
                        </Link>
                      </div>
                      <div className="col-4 ">
                        <Link to="/forex/EURUSD" className="checkbox">
                          <label className="checkbox-wrapper">
                            <span className="checkbox-tile">
                              <span className="checkbox-icon">
                                <img src="/img/fx.png" alt="" />
                              </span>
                              <span className="checkbox-label mt-2">
                                {t("FX")}
                              </span>
                            </span>
                          </label>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="p-3  bg2 br20 mb-4">
                <div className=" row  dashbord align-items-center">
                  <div className="col-md-6">
                    <h5 className="mb-3 mb-md-0">
                      Setup Leverage{" "}
                      <i
                        className="bi bi-question-circle cursor-pointer"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Default leverage is 1:1"
                        data-tooltip-place="top"
                      ></i>
                      <Tooltip id="my-tooltip" />
                    </h5>
                  </div>
                  <div className="col-md-4 ml-auto">
                    <select
                      className="form-control"
                      onChange={handleChange}
                      value={userLeverage}
                    >
                      <option value={""}>Select Leverage</option>
                      {leverageList.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            1:{item.amount}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="p-3   bg2 br20">
                <div className="row">
                  <div className="col-md-3">
                    {" "}
                    <h5 className="mb-3">
                      {t("User ID")}:<span className="ml-3">{username}</span>
                    </h5>
                  </div>
                  <div className="col-md-3">
                    {" "}
                    <h5 className="mb-3">
                      {t("Leverage")}:
                      <span className="ml-3">1:{userLeverage}</span>
                    </h5>
                  </div>
                </div>

                <hr />
                <h5 className="mb-3">{t("Portfolio")}</h5>
                <div className="row current_unds_item">
                  <div className="col-6 col-md-4">
                    <p className="mb-sm-2 mb-1">
                      {t("Crypto")} {t("Portfolio")}
                    </p>
                    <h4>
                      {/* $
                      {isNaN(cryptoPortfolioValue)
                        ? "0.00"
                        : formatToThreeDecimalPlaces(cryptoPortfolioValue)} */}
                      ${isNaN(equityPortfolio) ? "0.00" : equityPortfolio}
                    </h4>
                  </div>
                  <div className="col-6 col-md-4">
                    <p className="mb-sm-2 mb-1">
                      {t("Equity")} {t("Portfolio")}
                    </p>
                    <h4>
                      ${isNaN(equityPortfolio) ? "0.00" : equityPortfolio}
                    </h4>
                  </div>
                  <div className="col-6 col-md-4">
                    <p className="mb-sm-2 mb-1">
                      {t("FX")} {t("Portfolio")}
                    </p>
                    <h4>
                      ${isNaN(floatingProfits) ? "0.00" : floatingProfits}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Dashboard;
