import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Login from "./components/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./components/pages/Home";
import { Toaster } from "react-hot-toast";
import Register from "./components/auth/Register";
import OnBoarding from "./components/pages/OnBoarding";
import Dashboard from "./components/widgets/Dashboard";
import CryptoExchange from "./components/pages/CryptoExchange";
import Forgot from "./components/auth/Forgot";
import Kyc from "./components/pages/Kyc";
import Support from "./components/pages/Support";
import Chatting from "./components/pages/Chatting";
import NotFound from "./components/widgets/404";
import ForexExchange from "./components/pages/ForexExchange";
import CryptoAssets from "./components/pages/CryptoAssets";
import GoogleAuthentication from "./components/pages/GoogleAuthentication";
import CryptoTradeHistory from "./components/pages/CryptoTradeHistory";
import CryptoTransactionHistory from "./components/pages/CryptoTransactionHistory";
import MobileChart from "./components/partials/MobileChart";
import EquityExchange from "./components/pages/EquityExchange";
import ForexMobile from "./components/partials/ForexMobile";
import UserActivity from "./components/pages/UserActivity";
import ChangePassword from "./components/pages/ChangePassword";
import ForexExchangeEquity from "./components/pages/ForexExchangeEquity";
import TradingViewCryptoMobile from "./components/partials/TradingViewCryptoMobile";

const App = () => {
  const { login } = useAuth();

  useEffect(() => {
    // toast.success("User Login Successfully", {
    //   style: {
    //     borderRadius: "10px",
    //     background: "#08223e",
    //     color: "#fff",
    //   },
    // });
    // Check if a JWT token is stored in localStorage and log in the user if found
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route
            path="/onboarding"
            element={<ProtectedRoute component=<OnBoarding /> />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component=<Dashboard /> />}
          />
          <Route
            path="/crypto/:firstCoin/:secondCoin"
            element={<ProtectedRoute component=<CryptoExchange /> />}
          />
          <Route
            path="/mobile-crypto/:firstCoin/:secondCoin"
            element={<TradingViewCryptoMobile />}
          />
          <Route path="/mobile-forex/:symbol" element={<ForexMobile />} />
          <Route
            path="/stocks/:symbol"
            element={<ProtectedRoute component=<EquityExchange /> />}
          />
          <Route
            path="/forex/:symbol"
            element={<ProtectedRoute component=<ForexExchange /> />}
          />
          <Route
            path="/forex-equity/:symbol"
            element={<ProtectedRoute component=<ForexExchangeEquity /> />}
          />

          <Route
            path="/verify-identity"
            element={<ProtectedRoute component=<Kyc /> />}
          />
          <Route
            path="/support"
            element={<ProtectedRoute component=<Support /> />}
          />
          <Route
            path="/change-password"
            element={<ProtectedRoute component=<ChangePassword /> />}
          />
          <Route
            path="/account"
            element={<ProtectedRoute component=<CryptoAssets /> />}
          />
          <Route
            path="/two-factory"
            element={<ProtectedRoute component=<GoogleAuthentication /> />}
          />
          <Route
            path="/trade-history"
            element={<ProtectedRoute component=<CryptoTradeHistory /> />}
          />
          <Route
            path="/transaction-history"
            element={<ProtectedRoute component=<CryptoTransactionHistory /> />}
          />
          <Route
            path="/activity"
            element={<ProtectedRoute component=<UserActivity /> />}
          />
          <Route
            path="/Chatting/:ticketId/:status"
            element={<ProtectedRoute component=<Chatting /> />}
          />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Toaster position="top-right" />
    </div>
  );
};

export default App;
