import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import Sidebar from "../widgets/Sidebar";
import {
  useGetCryptoTradeHistoryQuery,
  useGetForexTradeHistoryQuery,
  useGetEquityTradeHistoryQuery,
} from "../../redux/tradeApi";
import moment from "moment";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";

const CryptoTradeHistory = () => {
  const { t } = useTranslation();
  const [selectedSegment, setSelectedSegment] = useState("crypto");
  const { data: cryptoTradeData } = useGetCryptoTradeHistoryQuery();
  const { data: forexTradeData } = useGetForexTradeHistoryQuery();
  const { data: equityTradeData } = useGetEquityTradeHistoryQuery();
  const [cryptoTradeDataApi, setCryptoTradeDataApi] = useState([]);
  const [cryptoListData, setCryptoListData] = useState([]);
  const [forexData, setForexData] = useState([]);
  const [selectedCryptoCoin, setSelectedCryptoCoin] = useState("");
  const [tradeType, setTradeType] = useState("buy");
  const [equityData, setEquityData] = useState([]);

  useEffect(() => {
    if (cryptoTradeData) {
      const cryptoData = cryptoTradeData[0];
      setCryptoTradeDataApi(cryptoData.orderHistory);
      setCryptoListData(cryptoData.coinListQry);
    }
    if (forexTradeData) {
      if (forexTradeData.status === true) {
        const forexDataApi = forexTradeData.data;
        setForexData(forexDataApi);
      }
    }
    if (equityTradeData) {
      if (equityTradeData.status) {
        setEquityData(equityTradeData.data);
      }
    }
  }, [cryptoTradeData]);

  // const showTableHtmlCrypto = () => {
  //   if (cryptoTradeDataApi.length > 0) {
  //     let filteredTransactions = cryptoTradeDataApi.filter((e) =>
  //       e.type === "buy"
  //         ? e.buyFirstCoin === selectedCryptoCoin
  //         : e.buySecondCoin === selectedCryptoCoin
  //     );

  //     filteredTransactions = filteredTransactions.filter(
  //       (item) => item.type === tradeType
  //     );

  //     if (filteredTransactions.length > 0) {
  //       return filteredTransactions.map((item, i) => {
  //         const amount =
  //           item.type === "buy"
  //             ? parseFloat(item.get_amount)
  //             : parseFloat(item.spend_amount);
  //         const buyFees = parseFloat(item.buy_fees);
  //         const total = parseFloat(
  //           item.type === "buy"
  //             ? (item.per_price * amount + buyFees).toFixed(8)
  //             : (item.per_price * amount - buyFees).toFixed(8)
  //         ); // Adjusted to 8 decimal places, change as needed

  //         return (
  //           <tr key={i}>
  //             <td>{i + 1}</td>
  //             <td>{item.type === "buy" ? "Buy" : "Sell"}</td>
  //             <td>{item.coin_pair}</td>
  //             <td>{item.per_price}</td>
  //             <td>{amount}</td>
  //             <td>{item.buy_fees}</td>
  //             <td>{total}</td>
  //             <td style={{ textAlign: "center" }}>
  //               {item.orderMethod ? item.orderMethod : "-"}
  //             </td>
  //             <td>{moment(item.created_at).format("lll")}</td>
  //           </tr>
  //         );
  //       });
  //     } else {
  //       return (
  //         <tr>
  //           <td colSpan={9} className="text-center">
  //             No Data Found
  //           </td>
  //         </tr>
  //       );
  //     }
  //   } else {
  //     return (
  //       <tr>
  //         <td colSpan={9} className="text-center">
  //           No Data Found
  //         </td>
  //       </tr>
  //     );
  //   }
  // };

  const showTableHtmlCrypto = () => {
    let dataToDisplay = cryptoTradeDataApi;

    if (selectedCryptoCoin && selectedCryptoCoin !== "") {
      dataToDisplay = dataToDisplay.filter((e) =>
        e.type === "buy"
          ? e.buyFirstCoin === selectedCryptoCoin
          : e.buySecondCoin === selectedCryptoCoin
      );
    }

    if (tradeType) {
      dataToDisplay = dataToDisplay.filter((item) => item.type === tradeType);
    }

    if (dataToDisplay.length > 0) {
      return dataToDisplay.map((item, i) => {
        const amount =
          item.type === "buy"
            ? parseFloat(item.get_amount)
            : parseFloat(item.spend_amount);
        const buyFees = parseFloat(item.buy_fees);
        const total = parseFloat(
          item.type === "buy"
            ? (item.per_price * amount + buyFees).toFixed(8)
            : (item.per_price * amount - buyFees).toFixed(8)
        ); // Adjusted to 8 decimal places, change as needed

        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item.type === "buy" ? "Buy" : "Sell"}</td>
            <td>{item.coin_pair}</td>
            <td>{item.per_price}</td>
            <td>{amount}</td>
            <td>{item.buy_fees}</td>
            <td>{total}</td>
            <td style={{ textAlign: "center" }}>
              {item.orderMethod ? item.orderMethod : "-"}
            </td>
            <td>{moment(item.created_at).format("lll")}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={9} className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedCryptoCoin(value);
  };

  const handleTradeType = (type) => {
    setTradeType(type);
  };

  const formatNumberIso = (number) => {
    return number.toNumber();
  };

  const forexRenderHtml = () => {
    if (forexData.length > 0) {
      return forexData.map((item, i) => {
        const currentPricea = new BigNumber(item.closed_price);
        const orderPrice = new BigNumber(item.price);
        const quantity = parseFloat(item.quantity) * parseFloat(100000);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        const flotingProfit = priceChange.multipliedBy(quantity);
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item.symbol}</td>
            <td>{item.order_side}</td>
            <td>{item.quantity}</td>
            <td>{moment(item.updated_at).format("lll")}</td>
            <td>
              {item.price} <i className="bi bi-arrow-right"></i>{" "}
              {item.closed_price}
            </td>
            <td>
              ${isNaN(flotingProfit) ? "0.0" : formatNumberIso(flotingProfit)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7} className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  const equityRenderHtml = () => {
    if (equityData.length > 0) {
      return equityData.map((item, i) => {
        const currentPricea = new BigNumber(item.closed_price);
        const orderPrice = new BigNumber(item.price);
        const quantity = parseFloat(item.quantity);
        let priceChange;
        if (item.order_side === "BUY") {
          priceChange = currentPricea.minus(orderPrice);
        } else if (item.order_side === "SELL") {
          priceChange = orderPrice.minus(currentPricea);
        }
        const flotingProfit = priceChange.multipliedBy(quantity);
        return (
          <tr key={i}>
            <td>{i + 1}</td>
            <td>{item.symbol}</td>
            <td>{item.order_side}</td>
            <td>{item.quantity}</td>
            <td>{moment(item.updated_at).format("lll")}</td>
            <td>
              {item.price} <i className="bi bi-arrow-right"></i>{" "}
              {item.closed_price}
            </td>
            <td>
              ${isNaN(flotingProfit) ? "0.0" : formatNumberIso(flotingProfit)}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7} className="text-center">
            No Data Found
          </td>
        </tr>
      );
    }
  };

  return (
    <div>
      <Navbar />
      <section className="p70 pt-md-5 pb-md-5">
        <div className="container ">
          <div className="row ">
            <Sidebar />
            <div className="col-xl-9 col-lg-8">
              <div className="nav-tabs3  bg2 br10 mb-4">
                <button
                  className={selectedSegment === "crypto" && "active"}
                  onClick={() => setSelectedSegment("crypto")}
                >
                  Crypto History
                </button>
                <button
                  className={selectedSegment === "forex" && "active"}
                  onClick={() => setSelectedSegment("forex")}
                >
                  Forex History
                </button>
                <button
                  className={selectedSegment === "equity" && "active"}
                  onClick={() => setSelectedSegment("equity")}
                >
                  Equity History
                </button>
              </div>

              <div className="p-3  bg2 br20 ">
                {selectedSegment === "crypto" ? (
                  <>
                    <div className="row align-items-center mb-2">
                      <h5 className="nav-item m-0 col-auto">
                        {t("Crypto")} {t("Trade History")}
                      </h5>
                      <div className="buyselll_box ml-auto col-auto">
                        <ul className="nav buy_sell_tab   ">
                          <li className="nav-item ">
                            <button
                              onClick={() => handleTradeType("buy")}
                              className={tradeType === "buy" ? "active" : ""}
                            >
                              Buy
                            </button>
                          </li>
                          <li className="nav-item ">
                            <button
                              onClick={() => handleTradeType("sell")}
                              className={tradeType === "sell" ? "active" : ""}
                            >
                              Sell
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="col-auto mt-md-0 mt-3">
                        <select
                          className="form-control select_crypto"
                          value={selectedCryptoCoin}
                          onChange={handleChange}
                        >
                          <option value={""}>Select Crypto</option>
                          {cryptoListData.map((item) => {
                            return (
                              <option value={item.symbol}>{item.symbol}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive trade_scrool_table">
                      <table className="table table-striped mb-0 ">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Trade Type</th>
                            <th>Pair </th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Trade Fee</th>
                            <th>Total</th>
                            <th>Order Method</th>
                            <th>Created</th>
                          </tr>
                        </thead>
                        <tbody>{showTableHtmlCrypto()}</tbody>
                      </table>
                    </div>
                  </>
                ) : selectedSegment === "forex" ? (
                  <>
                    <div className="row align-items-center mb-2">
                      <h5 className="nav-item m-0 col-auto">
                        {t("FX")} {t("Trade History")}
                      </h5>
                    </div>
                    <div className="table-responsive trade_scrool_table">
                      <table className="table table-striped mb-0 ">
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Symbol</th>
                            <th>Direction</th>
                            <th>Volume, lots</th>
                            <th>Close time</th>
                            <th>Price change</th>
                            <th>Profit & Loss</th>
                          </tr>
                        </thead>
                        <tbody>{forexRenderHtml()}</tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <>
                      <div className="row align-items-center mb-2">
                        <h5 className="nav-item m-0 col-auto">
                          {t("Equity")} {t("Trade History")}
                        </h5>
                      </div>
                      <div className="table-responsive trade_scrool_table">
                        <table className="table table-striped mb-0 ">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Symbol</th>
                              <th>Direction</th>
                              <th>Volume, lots</th>
                              <th>Close time</th>
                              <th>Price change</th>
                              <th>Profit & Loss</th>
                            </tr>
                          </thead>
                          <tbody>{equityRenderHtml()}</tbody>
                        </table>
                      </div>
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CryptoTradeHistory;
