import React, { useEffect, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import { CountryDropdown } from "react-country-region-selector";
import { useGetallUserQuery, useSubmitKycMutation } from "../../redux/userApi";
import PhoneInput from "react-phone-input-2";
import { KyxValid } from "../validations/KYCValid";
import toast from "react-hot-toast";
import KycStatus from "./KycStatus";
import { sendOtpKyc, verifyOtpKyc } from "../services/Kyc";
import { useTranslation } from "react-i18next";

const Kyc = () => {
  const { t } = useTranslation();
  const { data, refetch } = useGetallUserQuery();
  const [submitKyc, { isLoading }] = useSubmitKycMutation();
  const [kycStatus, setKycStatus] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [disableGetCode, setDisableGetCode] = useState(false);
  const [codeText, setCodeText] = useState("Get Code");
  const [country, setCountry] = useState("");
  const [enabledValue, setEnabledValue] = useState("");
  const [filedType, setFiledType] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const [formData, setFormData] = useState({
    contactValue: "",
    country: null,
    verificationCode: "",
  });
  const [formDataErr, setFormDataErr] = useState({
    contactValue: "",
    country: "",
    verificationCode: "",
  });
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentNumberErr, setDocumentNumberErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [documentTypeErr, setDocumentTypeErr] = useState("");
  const [documentFrontImgr, setDocumentFrontImgr] = useState("");
  const [documentFrontImg, setDocumentFrontImg] = useState("/img/idcard.jpg");
  const [documentFrontImgErr, setDocumentFrontImgErr] = useState("");
  const [documentBackImgr, setDocumentBackImgr] = useState("");
  const [documentBackImg, setDocumentBackImg] = useState("/img/idcard.jpg");
  const [documentBackImgErr, setDocumentBackImgErr] = useState("");
  const [imageState, setImageState] = useState("");
  const [isFirstImgeUploaded, setIsFirstImgeUploaded] = useState(false);
  const [isSecondImageUploaded, setIsSecondImageUploaded] = useState(false);
  const [docDisabled, setDocDisabled] = useState(false);
  const [utilityBillImgr, setUtilityBillImgr] = useState("");
  const [utilityBillImg, setUtilityBillImg] = useState("/img/idcard.jpg");
  const [utilityBillImgErr, setUtilityBillImgErr] = useState("");
  const [selfieImager, setSelfieImager] = useState("");
  const [selfieImage, setSelfieImage] = useState("/img/idcard.jpg");
  const [selfieImageErr, setSelfieImageErr] = useState("");

  console.log(data, "data");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data) {
          if (data?.is_contact_verified === "yes") {
            setCurrentStep(3);
            setKycStatus(data.kyc_status);
          }
          if (data?.mobile_number === null) {
            setEnabledValue("mobile");
          } else {
            setEnabledValue("email");
          }
        }
      } catch (error) {
        console.error("Error fetching tickit chat:", error);
      }
    };

    fetchData();
  }, [data]);

  const validateCode = async (code) => {
    if (!formData.contactValue) {
      setFormDataErr((prevErrors) => ({
        ...prevErrors,
        contactValue: "This field is required",
      }));
      return false;
    }
    const data = {
      filedType: enabledValue,
      currentValue: formData.contactValue,
      otp: code,
    };
    const validateCodeRes = await verifyOtpKyc(data);
    if (validateCodeRes.status) {
      toast.success(validateCodeRes.message);
      setDisableGetCode(true);
      setCodeText("Verified");
      handleStepChange();
    } else {
      toast.error(validateCodeRes.message);
      setCodeText("Resend Code");
      setDisableGetCode(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "verificationCode") {
      const isNumeric = /^[0-9]+$/.test(value);
      console.log("isSixDigitNumber", isNumeric);
      if (value.length > 6) {
        return;
      }
      if (isNumeric && value.length === 6) {
        validateCode(value);
      }
    }
    setFormData({ ...formData, [name]: value });

    const checkRegister = KyxValid(name, value);
    setFormDataErr({ ...formDataErr, [name]: checkRegister });
    if (name === "contactValue") {
      if (!value) {
        setFormDataErr((prevErrors) => ({
          ...prevErrors,
          contactValue: "This field is required",
        }));
        return false;
      }

      const emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\d\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

      if (!value.match(emailReg)) {
        setFormDataErr((prevErrors) => ({
          ...prevErrors,
          contactValue: "Please enter valid email",
        }));
        return false;
      }

      setFormDataErr({
        ...formDataErr,
        contactValue: "",
      });

      setCodeText("Get Code");

      setFiledType("email");
    }
  };

  const handleObBlur = (e) => {
    const { name } = e.target;

    if (name === "contactValue" && formData.contactValue === "") {
      setFormDataErr((prevErrors) => ({
        ...prevErrors,
        contactValue: "This field is required",
      }));
    } else if (
      name === "verificationCode" &&
      formData.verificationCode === ""
    ) {
      setFormDataErr((prevErrors) => ({
        ...prevErrors,
        verificationCode: "This field is required",
      }));
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    const otpData = {
      contactValue: formData.contactValue,
      filedType: filedType,
    };
    const result = await sendOtpKyc(otpData);
    if (result.status) {
      toast.success(result.message);
      setCodeText("Resend Code");
    } else {
      toast.error(result.message);
      return;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const selectResidetialCountry = (val) => {
    console.log(val, "vall");
    setCountry(val);
    setCountryErr("");
  };

  const handleDocumentTypeChange = (type) => {
    setSelectedDocumentType(type);
    setDocumentNumber("");
    setDocumentNumberErr("");
    setDocumentTypeErr("");
  };

  const handleDocumentNumberChange = (value) => {
    setDocumentNumber(value);
    validateDocumentNumber(value);
  };

  const validateDocumentNumber = (value) => {
    // Add your validation logic based on the selected document type
    let error = "";

    if (selectedDocumentType === "idCard") {
      // Example ID Card validation (4-15 alphanumeric characters)
      const regex = /^[a-zA-Z0-9]{4,15}$/;
      if (!regex.test(value)) {
        error = "Invalid ID Card number";
      }
    } else if (selectedDocumentType === "passport") {
      // Example Passport validation (6-10 alphanumeric characters)
      const regex = /^[a-zA-Z0-9]{6,10}$/;
      if (!regex.test(value)) {
        error = "Invalid Passport number";
      }
    } else if (selectedDocumentType === "driversLicense") {
      // Example Driver's License validation (8-20 alphanumeric characters)
      const regex = /^[a-zA-Z0-9]{8,20}$/;
      if (!regex.test(value)) {
        error = "Invalid Driver's License number";
      }
    }

    setDocumentNumberErr(error);
  };

  const handleStepChange = (e) => {
    console.log("step", currentStep);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleOnChange = (value, country) => {
    const name = "contactValue";
    setFormDataErr({
      ...formDataErr,
      [name]: "",
    });
    setCodeText("Get Code");
    setFiledType("mobile");
    setFormData({ ...formData, contactValue: value });
  };

  const handleStepChangeValidate = () => {
    if (!country) {
      setCountryErr("Please select country");
    }
    if (!selectedDocumentType) {
      setDocumentTypeErr("Please select document type");
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    if (!documentNumber) {
      setDocumentNumberErr("Please enter document number");
      return false;
    }
    const imageFront = e.target.files[0];
    if (!imageFront.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setDocumentFrontImgErr("Please select valid image jpg|jpeg|png|gif.");
      return false;
    }
    setImageState("Front Side");
    setIsFirstImgeUploaded(true);
    setDocDisabled(true);
    setDocumentFrontImgr(imageFront);
    setDocumentFrontImg(URL.createObjectURL(imageFront));
  };

  const handleImageUploadSecond = (e) => {
    e.preventDefault();
    const imageBack = e.target.files[0];
    if (!imageBack.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setDocumentBackImgErr("Please select valid image jpg|jpeg|png|gif.");
      return false;
    } else {
      setDocumentBackImgErr("");
    }
    const validSizeMin = 10000; // 10KB
    const validSizeMax = 5000000; // 5MB
    const fileSize = imageBack.size;
    if (fileSize < validSizeMin || fileSize > validSizeMax) {
      setDocumentBackImgErr("File size should be 10KB to 5MB");
      return false;
    } else {
      setDocumentBackImgErr("");
    }
    setImageState("Back Side");
    setIsSecondImageUploaded(true);
    setDocumentBackImgr(imageBack);
    setDocumentBackImg(URL.createObjectURL(imageBack));
    setCurrentStep(5);
  };

  const handleImageUploadBill = (e) => {
    e.preventDefault();
    const utilityBill = e.target.files[0];
    if (!utilityBill.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setUtilityBillImgErr("Please select valid image jpg|jpeg|png|gif.");
      return false;
    } else {
      setUtilityBillImgErr("");
    }
    const validSizeMin = 10000; // 10KB
    const validSizeMax = 5000000; // 5MB
    const fileSize = utilityBill.size;
    if (fileSize < validSizeMin || fileSize > validSizeMax) {
      setUtilityBillImgErr("File size should be 10KB to 5MB");
      return false;
    } else {
      setUtilityBillImgErr("");
    }
    setUtilityBillImgr(utilityBill);
    setUtilityBillImg(URL.createObjectURL(utilityBill));
    setCurrentStep(6);
  };

  const handleImageUploadSelfie = (e) => {
    e.preventDefault();
    const selfieImg = e.target.files[0];
    if (!selfieImg.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setSelfieImageErr("Please select valid image jpg|jpeg|png|gif.");
      return false;
    } else {
      setSelfieImageErr("");
    }
    const validSizeMin = 10000; // 10KB
    const validSizeMax = 5000000; // 5MB
    const fileSize = selfieImg.size;
    if (fileSize < validSizeMin || fileSize > validSizeMax) {
      setSelfieImageErr("File size should be 10KB to 5MB");
      return false;
    } else {
      setSelfieImageErr("");
    }
    setSelfieImager(selfieImg);
    setSelfieImage(URL.createObjectURL(selfieImg));
    setShowSubmit(true);
  };

  const handleKycSubmit = async () => {
    const formData = new FormData();
    formData.append("idFront", documentFrontImgr);
    formData.append("idBack", documentBackImgr);
    formData.append("idType", selectedDocumentType);
    formData.append("utilityBill", utilityBillImgr);
    formData.append("selfie", selfieImager);
    formData.append("idNumber", documentNumber);
    formData.append("country", country);

    const respData = await submitKyc(formData).unwrap();
    console.log(respData, "respData");

    if (respData.status) {
      toast.dismiss();
      toast.success(respData.message);
      refetch();
    } else {
      toast.dismiss();
      toast.error(respData.message);
    }
  };

  return (
    <>
      {kycStatus === "pending" ? (
        <KycStatus />
      ) : (
        <div>
          <Navbar />
          <section className="p70 pt-5">
            <div className="container ">
              <div className="row ">
                <div className="col-md-6 m-auto">
                  {currentStep === 1 && (
                    <div className=" p-3  p-md-4 bg2 br20 mb-4">
                      <h3 className="text-center">
                        {t("Identity Verification Process")}
                      </h3>
                      <p className="text-center mb-4 mb-md-5">
                        {t("Step-by-step process:")}
                      </p>

                      <div className="d-flex stap2_box mb-4 align-items-center">
                        <i className="bi bi-person-vcard"></i>
                        <div className="verified_text pl-3">
                          {/* <span>Step 1</span> */}
                          {t("Provide identity document")}
                        </div>
                      </div>
                      <div className="d-flex stap2_box mb-4">
                        <i className="bi bi-person-bounding-box"></i>
                        <div className="verified_text pl-3 align-items-center">
                          {/* <span>Step 2</span> */}
                          {t("Perform a liveness check")}
                        </div>
                      </div>
                      <a
                        className="btn w100 mt-5"
                        href="#!"
                        onClick={handleStepChange}
                      >
                        {t("Continue")}
                      </a>
                    </div>
                  )}
                  {currentStep === 2 && (
                    <div className=" p-3  p-md-4 bg2 br20 mb-4">
                      <h3 className="text-center">
                        {enabledValue === "email"
                          ? "Email Verification"
                          : "Mobile Number Verification"}
                      </h3>
                      <div className="stap2_box mb-4 align-items-center">
                        {enabledValue === "email" ? (
                          <form>
                            <div className="form-group">
                              <label>Enter Email Address</label>
                              <input
                                className="form-control"
                                autoComplete="off"
                                type="email"
                                name="contactValue"
                                id="contactValue"
                                placeholder="Enter Email Address"
                                value={formData.contactValue}
                                onChange={handleChange}
                                onBlur={handleObBlur}
                                disabled={disableGetCode}
                              />
                              <span className="text-danger">
                                {formDataErr.contactValue}
                              </span>
                            </div>
                            <div className="form-group">
                              <label>{t("Verification Code")}</label>
                              <div className="position-relative">
                                <input
                                  className="form-control"
                                  autoComplete="off"
                                  type="number"
                                  name="verificationCode"
                                  id="verificationCode"
                                  placeholder="Verification Code"
                                  value={formData.verificationCode}
                                  onChange={handleChange}
                                  onBlur={handleObBlur}
                                  disabled={disableGetCode}
                                  onKeyPress={handleKeyPress}
                                />
                                <button
                                  className="btn position-absolute verification_btn"
                                  type="submit"
                                  onClick={sendOtp}
                                  disabled={disableGetCode}
                                >
                                  {t(codeText)}
                                </button>
                              </div>
                              <span className="text-danger">
                                {formDataErr.verificationCode}
                              </span>
                            </div>
                          </form>
                        ) : (
                          <form>
                            <div className="form-group">
                              <label>Enter Mobile Number</label>
                              <PhoneInput
                                key={"phoneInput"}
                                country={"us"} // Default country (you can change it based on user's location)
                                value={formData.contactValue}
                                onChange={handleOnChange}
                                className=""
                                placeholder="Email/Mobile"
                                enableSearch={true}
                                countryCodeEditable={false}
                                inputProps={{
                                  autoFocus: true,
                                }}
                                disabled={disableGetCode}
                                name="contactValue"
                                id="contactValue"
                              />
                              <span className="text-danger">
                                {formDataErr.contactValue}
                              </span>
                            </div>
                            <div className="form-group">
                              <label>{t("Verification Code")}</label>
                              <div className="position-relative">
                                <input
                                  className="form-control"
                                  autoComplete="off"
                                  type="number"
                                  name="verificationCode"
                                  id="verificationCode"
                                  placeholder="Verification Code"
                                  value={formData.verificationCode}
                                  onChange={handleChange}
                                  onBlur={handleObBlur}
                                  disabled={disableGetCode}
                                  onKeyPress={handleKeyPress}
                                />
                                <button
                                  className="btn position-absolute verification_btn"
                                  type="submit"
                                  onClick={sendOtp}
                                  disabled={disableGetCode}
                                >
                                  {t(codeText)}
                                </button>
                              </div>
                            </div>
                            <span className="text-danger">
                              {formDataErr.verificationCode}
                            </span>
                          </form>
                        )}
                      </div>
                    </div>
                  )}
                  {currentStep === 3 && (
                    <div className=" p-3  p-md-4 bg2 br20 mb-4">
                      <h3 className="text-center mb-4 mb-md-5">
                        {t(
                          "Select type and issuing country of identity document"
                        )}
                      </h3>

                      <form>
                        <div className="form-group">
                          <label>
                            {t("Issuing Country")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <CountryDropdown
                            className="form-control"
                            name="country"
                            value={country}
                            onChange={(val) => selectResidetialCountry(val)}
                          />
                          <span className="text-danger">{countryErr}</span>
                        </div>
                        <div className="form-group">
                          <label>
                            {t("Document Type")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <div className="radio_button">
                              <input
                                className="  option-input"
                                type="radio"
                                checked={selectedDocumentType === "idCard"}
                                id="idCardRadio"
                                onChange={() =>
                                  handleDocumentTypeChange("idCard")
                                }
                              />
                              <label
                                className="form-check-label  mb-0 cursor-pointer"
                                htmlFor="idCardRadio"
                              >
                                {t("ID Card")}
                              </label>
                            </div>
                            <div className="radio_button">
                              <input
                                className=" option-input"
                                type="radio"
                                checked={selectedDocumentType === "passport"}
                                id="passportRadio"
                                onChange={() =>
                                  handleDocumentTypeChange("passport")
                                }
                              />
                              <label
                                className="form-check-label mb-0 cursor-pointer"
                                htmlFor="passportRadio"
                              >
                                {t("Passport")}
                              </label>
                            </div>
                            <div className="radio_button">
                              <input
                                className=" option-input"
                                type="radio"
                                checked={
                                  selectedDocumentType === "driversLicense"
                                }
                                id="driversLicenseRadio"
                                onChange={() =>
                                  handleDocumentTypeChange("driversLicense")
                                }
                              />
                              <label
                                className="form-check-label  mb-0 cursor-pointer"
                                htmlFor="driversLicenseRadio"
                              >
                                {t("Driver's License")}
                              </label>
                            </div>
                          </div>
                          <span className="text-danger">{documentTypeErr}</span>
                        </div>
                      </form>

                      <a
                        className="btn w100 mt-5"
                        href="#"
                        onClick={handleStepChangeValidate}
                      >
                        Next
                      </a>
                    </div>
                  )}
                  {currentStep === 4 && (
                    <div className=" p-3  p-md-4 bg2 br20 mb-4">
                      <h3 className="text-center mb-4 mb-md-5">
                        Get ready to upload your{" "}
                        {selectedDocumentType === "idCard"
                          ? "ID Card"
                          : selectedDocumentType === "passport"
                          ? "Passport"
                          : "Driver's License"}{" "}
                      </h3>

                      <form onSubmit={(e) => e.preventDefault()}>
                        {isSecondImageUploaded === false ? (
                          <div className="form-group id_card_image br10 front">
                            <img
                              className="img-fluid"
                              src={documentFrontImg}
                              alt="idcard"
                            />
                            <span className="text-danger">
                              {documentFrontImgErr}
                            </span>
                          </div>
                        ) : (
                          <div className="form-group id_card_image br10 back">
                            <img
                              className="img-fluid"
                              src={documentBackImg}
                              alt="idcard"
                            />
                            <span className="text-danger">
                              {documentBackImgErr}
                            </span>
                          </div>
                        )}
                        <h5 className="text-center mb-4">
                          {" "}
                          {selectedDocumentType === "idCard"
                            ? "ID Card"
                            : selectedDocumentType === "passport"
                            ? "Passport"
                            : "Driver's License"}{" "}
                          {imageState && "/ " + imageState}
                        </h5>
                        {selectedDocumentType && (
                          <div className="form-group">
                            <label>
                              {selectedDocumentType === "idCard"
                                ? "ID Card"
                                : selectedDocumentType === "passport"
                                ? "Passport"
                                : "Driver's License"}{" "}
                              Number <span className="text-danger">*</span>
                            </label>
                            <input
                              type={"text"}
                              className="form-control"
                              placeholder={
                                selectedDocumentType === "idCard"
                                  ? "Enter ID Card"
                                  : selectedDocumentType === "passport"
                                  ? "Enter Passport"
                                  : "Enter Driver's License"
                              }
                              value={documentNumber}
                              disabled={docDisabled}
                              onChange={(e) =>
                                handleDocumentNumberChange(e.target.value)
                              }
                            />
                            <span className="text-danger">
                              {documentNumberErr}
                            </span>
                          </div>
                        )}
                        <div className="form-group">
                          <h5>Tips</h5>
                          <ul className="typs_list mt-3">
                            <li>
                              {" "}
                              <i className="bi bi-check-circle mr-2"></i>
                              Upload a colored photo or file
                            </li>
                            <li>
                              <i className="bi bi-check-circle mr-2"></i>
                              Take a photo in a well lit room
                            </li>
                            <li>
                              <i className="bi bi-x-circle mr-2"></i>Don't edit
                              images of your document
                            </li>
                          </ul>
                        </div>
                      </form>
                      {isFirstImgeUploaded === false ? (
                        <a
                          className="btn w100 mt-5 upload_btn position-relative"
                          href="#"
                        >
                          Upload Front Side
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            className=""
                            onChange={handleImageUpload}
                          />
                        </a>
                      ) : (
                        <a
                          className="btn w100 mt-5 upload_btn position-relative"
                          href="#"
                        >
                          Upload Back Side
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageUploadSecond}
                          />
                        </a>
                      )}
                    </div>
                  )}
                  {currentStep === 5 && (
                    <div className=" p-3  p-md-4 bg2 br20 mb-4">
                      <h3 className="text-center mb-4 mb-md-5">
                        Get ready to upload your Utility Bill
                      </h3>

                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group id_card_image br10 front">
                          <img
                            className="img-fluid"
                            src={utilityBillImg}
                            alt="idcard"
                          />
                          <span className="text-danger">
                            {utilityBillImgErr}
                          </span>
                        </div>
                        <h5 className="text-center mb-4">Utility Bill</h5>

                        <div className="form-group">
                          <h5>Tips</h5>
                          <ul className="typs_list mt-3">
                            <li>
                              {" "}
                              <i className="bi bi-check-circle mr-2"></i>
                              Upload a colored photo or file
                            </li>
                            <li>
                              <i className="bi bi-check-circle mr-2"></i>
                              Take a photo in a well lit room
                            </li>
                            <li>
                              <i className="bi bi-x-circle mr-2"></i>Don't edit
                              images of your document
                            </li>
                          </ul>
                        </div>
                      </form>

                      <a
                        className="btn w100 mt-5 upload_btn position-relative"
                        href="#"
                      >
                        Upload Utility Bill
                        <input
                          type="file"
                          className=""
                          accept=".jpg, .jpeg, .png"
                          onChange={handleImageUploadBill}
                        />
                      </a>
                    </div>
                  )}
                  {currentStep === 6 && (
                    <div className=" p-3  p-md-4 bg2 br20 mb-4">
                      <h3 className="text-center mb-4 mb-md-5">
                        Get ready to upload your Selfie{" "}
                      </h3>

                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group id_card_image br10 front">
                          <img
                            className="img-fluid"
                            src={selfieImage}
                            alt="idcard"
                          />
                          <span className="text-danger">{selfieImageErr}</span>
                        </div>

                        <h5 className="text-center mb-4"> Selfie</h5>
                        <div className="form-group">
                          <h5>Tips</h5>
                          <ul className="typs_list mt-3">
                            <li>
                              {" "}
                              <i className="bi bi-check-circle mr-2"></i>
                              Upload a colored photo or file
                            </li>
                            <li>
                              <i className="bi bi-check-circle mr-2"></i>
                              Take a photo in a well iit room
                            </li>
                            <li>
                              <i className="bi bi-x-circle mr-2"></i>Don't edit
                              images of your document
                            </li>
                          </ul>
                        </div>
                      </form>

                      {showSubmit ? (
                        <button
                          disabled={isLoading}
                          onClick={handleKycSubmit}
                          className="btn w100 mt-5 upload_btn position-relative"
                        >
                          {isLoading ? "Submitting..." : "Submit"}
                        </button>
                      ) : (
                        <a
                          className="btn w100 mt-5 upload_btn position-relative"
                          href="#"
                        >
                          Upload Selfie Image
                          <input
                            type="file"
                            className=""
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageUploadSelfie}
                          />
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Kyc;
