import React, { useEffect, useRef, useState } from "react";
import Navbar from "../widgets/Navbar";
import Footer from "../widgets/Footer";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import {
  changePassword,
  sendCodeForgot,
  validateCodeResult,
} from "../services/Login";
import { ForgotValid } from "../validations/ForgotValid";
import { RegisterValid } from "../validations/RegisterValid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Forgot = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [inputMobile, setInputMobile] = useState("");
  const [selectedFiledType, setSelectedFiledType] = useState("email");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [otpFiledDisabled, setOtpFiledDisabled] = useState(true);
  const [forgotFields, setForgotFields] = useState({
    verificationCode: "",
  });
  const [forgotFieldsErr, setForgotFieldsErr] = useState({
    contactValue: "",
    verificationCode: "",
  });
  const [passwordFileds, setPasswordFileds] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordFiledErr, setPasswordFiledErr] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [disableGetCode, setDisableGetCode] = useState(false);
  const [codeText, setCodeText] = useState("Get Code");
  const [regTimer, setRegTimer] = useState(0);
  const [timerText, setTimerText] = useState("");
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [passwordShow, setPasswordShow] = useState({
    eye: "bi-eye-slash",
    type: "password",
  });
  const [confirmPasswordShow, setConfirmPasswordShow] = useState({
    eye: "bi-eye-slash",
    type: "password",
  });
  const [automateCountryCode, setAutomateCountryCode] = useState("us");
  const intervalRef = useRef(null);

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    setAutomateCountryCode(
      data.country_code ? data.country_code.toLowerCase() : "us"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const startCountdown = (seconds) => {
    let counter = seconds;
    intervalRef.current = setInterval(() => {
      setRegTimer(counter);
      setTimerText("Resend In");
      setBtnDisabled(true);
      setDisableGetCode(true);
      counter--;
      if (counter < 0) {
        clearInterval(intervalRef.current);
        setBtnDisabled(false);
        setCodeText("Resend Code");
        setTimerText("");
        setDisableGetCode(false);
      }
    }, 1000);
  };

  const handleOnChange = (value, country) => {
    const effectiveValue = value.replace(/[\s\+]/g, "");
    const isEffectivelyEmpty = effectiveValue === country.dialCode;
    setEmailErr("");
    if (isEffectivelyEmpty) {
      setSelectedFiledType("email");
      setMobile("");
      setEmailErr("This field is required");
    } else {
      setSelectedFiledType("mobile");
      setEmailErr("");
    }
    setCodeText("Get Code");
    setMobile(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "verificationCode") {
      if (value.length > 6) {
        return;
      }
      if (value.length === 6) {
        validateCode(value);
      }
    }
    setForgotFields({ ...forgotFields, [name]: value });
    setCodeText("Get Code");
    const checkRegister = ForgotValid(name, value);
    setForgotFieldsErr({ ...forgotFieldsErr, [name]: checkRegister });
    if (name === "contactValue") {
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      if (isMobile) {
        setSelectedFiledType("mobile");
      } else if (isEmail) {
        setSelectedFiledType("email");
      }
    }
  };

  const handleChangeNewPass = (e) => {
    const { name, value } = e.target;
    if (name === "verificationCode") {
      if (value.length > 6) {
        return;
      }
      if (value.length === 6) {
        validateCode(value);
      }
    }
    setPasswordFileds({ ...passwordFileds, [name]: value });

    const checkRegister = RegisterValid(name, value);
    setPasswordFiledErr({ ...passwordFiledErr, [name]: checkRegister });

    if (
      name === "newPassword" &&
      passwordFileds.confirmPassword &&
      passwordFileds.confirmPassword !== value
    ) {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password does not match",
      }));
    } else {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }

    if (name === "confirmPassword" && value === "") {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "This field is required",
      }));
    } else if (
      name === "confirmPassword" &&
      passwordFileds.newPassword &&
      passwordFileds.newPassword !== value
    ) {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password does not match",
      }));
    } else if (name !== "newPassword" && name !== "confirmPassword") {
      // Clear the confirmPassword error if the user is typing in other fields
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const handleObBlur = (e) => {
    const { name } = e.target;

    if (name === "email" && email === "") {
      setEmailErr("This field is required");
    } else if (name === "mobile" && mobile === "") {
      setMobileErr("This field is required");
    } else if (
      name === "verificationCode" &&
      forgotFields.verificationCode === ""
    ) {
      setForgotFieldsErr((prevErrors) => ({
        ...prevErrors,
        verificationCode: "This field is required",
      }));
    } else if (name === "newPassword" && passwordFileds.newPassword === "") {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        newPassword: "This field is required",
      }));
    } else if (
      name === "confirmPassword" &&
      passwordFileds.confirmPassword === ""
    ) {
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "This field is required",
      }));
    } else if (name !== "newPassword" && name !== "confirmPassword") {
      // Clear the confirmPassword error if the user is typing in other fields
      setPasswordFiledErr((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    if (!mobile && !email) {
      setEmailErr("This field is required");
      return false;
    }
    if (selectedFiledType === "email") {
      if (!/^[a-z0-9][a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/gi.test(email)) {
        setEmailErr("Please enter a valid email address");
        return false;
      }
    }
    if (selectedFiledType === "mobile" && mobile) {
      const isValidLength = mobile.length >= 7 && mobile.length <= 15;
      if (!isValidLength) {
        setMobileErr("Please enter a valid mobile number");
        return false;
      } else {
        setMobileErr("");
      }
    }
    const otpData = {
      filedType: selectedFiledType,
      contactValue: selectedFiledType === "email" ? email : mobile,
    };
    const result = await sendCodeForgot(otpData);
    if (result.status) {
      toast.success(result.message);
      startCountdown(30);
      setOtpFiledDisabled(false);
    } else {
      toast.error(result.message);
      return;
    }
  };

  const validateCode = async (code) => {
    if (!mobile && !email) {
      setEmailErr("This field is required");
      return false;
    }
    const data = {
      filedType: selectedFiledType,
      currentValue: selectedFiledType === "email" ? email : mobile,
      otp: code,
    };
    const validateCodeRes = await validateCodeResult(data);
    if (validateCodeRes.status) {
      toast.success(validateCodeRes.message);
      setDisableGetCode(true);
      setCodeText("Verified");
      setIsCodeVerified(true);
      clearInterval(intervalRef.current);
      setTimerText("");
      setRegTimer(0);
    } else {
      toast.error(validateCodeRes.message);
      setCodeText("Resend Code");
      setDisableGetCode(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const handleForgotSubmit = async (e) => {
    e.preventDefault();
    if (
      passwordFileds.newPassword === "" &&
      passwordFileds.confirmPassword === ""
    ) {
      let errors = {};

      for (let key in passwordFileds) {
        errors[key] = "This field is required";
      }

      setPasswordFiledErr({
        ...passwordFiledErr,
        ...errors,
      });
    } else {
      for (let key in passwordFileds) {
        const checkLogin = RegisterValid(key, passwordFileds[key]);
        setPasswordFiledErr({ ...passwordFiledErr, [key]: checkLogin });
        if (checkLogin !== "") {
          return false;
        }
      }

      const ForgotData = {
        contactValue: selectedFiledType === "email" ? email : mobile,
        newPassword: passwordFileds.newPassword,
        confirm_password: passwordFileds.confirmPassword,
        verification_code: forgotFields.verificationCode,
        filedType: selectedFiledType,
      };
      const result = await changePassword(ForgotData);
      if (result.status) {
        toast.success(result.message);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        toast.error(result.message);
      }
    }
  };

  const showcurrentPassword = () => {
    if (passwordShow.type === "password") {
      setPasswordShow({ eye: "bi-eye", type: "text" });
    } else {
      setPasswordShow({ eye: "bi-eye-slash", type: "password" });
    }
  };

  const showConfirmPassword = () => {
    if (confirmPasswordShow.type === "password") {
      setConfirmPasswordShow({ eye: "bi-eye", type: "text" });
    } else {
      setConfirmPasswordShow({ eye: "bi-eye-slash", type: "password" });
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const isEmail = /\S+@\S+\.\S+/.test(value);
      const isMobile = /^\d+$/.test(value);
      if (isMobile) {
        setSelectedFiledType("mobile");
        setInputMobile(value);
        setMobile(value);
        setEmailErr("");
        return;
      } else if (isEmail) {
        setSelectedFiledType("email");
      }
      setEmail(value);
      setCodeText("Get Code");
      const emailvalidation = RegisterValid(name, value);
      setEmailErr(emailvalidation);
      setMobileErr("");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login_form p70">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto">
              {isCodeVerified === false ? (
                <div className="bg3 p-3 p-md-4 br20">
                  <h3 className="mb-3">{t("Forgot Password")}</h3>
                  <form className="">
                    <div className="form-group">
                      <label>{t("Email or Mobile")}</label>
                      {selectedFiledType === "mobile" ? (
                        <PhoneInput
                          key={"phoneInput"}
                          country={automateCountryCode} // Default country (you can change it based on user's location)
                          value={inputMobile}
                          onChange={handleOnChange}
                          className=""
                          placeholder="Email or Mobile Number"
                          enableSearch={true}
                          containerClass="baseclass"
                          inputClass="somebaseclass"
                          countryCodeEditable={false}
                          inputProps={{
                            autoFocus: true,
                            name: "mobile",
                          }}
                          disabled={disableGetCode}
                          onBlur={handleObBlur}
                        />
                      ) : (
                        <input
                          key={"regularInput"}
                          className="form-control"
                          autoComplete="off"
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email or Mobile Number"
                          value={email}
                          onChange={handleChangeEmail}
                          onBlur={handleObBlur}
                          disabled={disableGetCode}
                          autoFocus
                          onPaste={(e) => e.preventDefault()}
                        />
                      )}
                      {emailErr && (
                        <span className="text-danger">{t(emailErr)}</span>
                      )}
                      {mobileErr && (
                        <span className="text-danger">{t(mobileErr)}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>{t("Verification Code")}</label>
                      <div className=" position-relative">
                        <input
                          className="form-control"
                          autoComplete="off"
                          type="number"
                          name="verificationCode"
                          id="verificationCode"
                          onChange={handleChange}
                          placeholder="Verification Code"
                          value={forgotFields.verificationCode}
                          disabled={regTimer > 0 ? false : otpFiledDisabled}
                          onKeyPress={handleKeyPress}
                          onBlur={handleObBlur}
                        />
                        <button
                          className="btn position-absolute verification_btn"
                          type="submit"
                          onClick={sendOtp}
                          disabled={btnDisabled}
                        >
                          {t(timerText)}
                          {"  "}
                          {regTimer === 0 ? t(codeText) : t(regTimer)}
                        </button>
                      </div>
                      <span className="text-danger">
                        {t(forgotFieldsErr.verificationCode)}
                      </span>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="bg3 p-3 p-md-4 br20">
                  <h3 className="mb-3">{t("Forgot Password")}</h3>
                  <form className="" onSubmit={handleForgotSubmit}>
                    <div className="form-group">
                      <label>{t("New Password")}</label>
                      <div className="eye_pass position-relative">
                        <input
                          key={"regularInput"}
                          className="form-control"
                          autoComplete="off"
                          type={passwordShow.type}
                          name="newPassword"
                          id="newPassword"
                          placeholder="Enter New Password"
                          value={passwordFileds.newPassword}
                          onChange={handleChangeNewPass}
                          onBlur={handleObBlur}
                        />
                        <span
                          className="password__show position-absolute eye1"
                          onClick={showcurrentPassword}
                        >
                          <i className={`bi ${passwordShow.eye}`}></i>
                        </span>
                        <span className="text-danger">
                          {t(passwordFiledErr.newPassword)}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{t("Confirm Password")}</label>
                      <div className=" position-relative">
                        <div className="eye_pass position-relative">
                          <input
                            className="form-control"
                            autoComplete="off"
                            type={confirmPasswordShow.type}
                            name="confirmPassword"
                            id="confirmPassword"
                            onChange={handleChangeNewPass}
                            placeholder="Enter Confirm Password"
                            value={passwordFileds.confirmPassword}
                            onBlur={handleObBlur}
                          />
                        </div>
                        <span
                          className="password__show position-absolute eye1"
                          onClick={showConfirmPassword}
                        >
                          <i className={`bi ${confirmPasswordShow.eye}`}></i>
                        </span>
                        <span className="text-danger">
                          {t(passwordFiledErr.confirmPassword)}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group log-btn mx-auto">
                        <button className="btn w100" type="submit">
                          {t("Submit")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Forgot;
